









import { Component, Vue } from 'vue-property-decorator';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import Simulador from '@/plugins/api/Simulador';

@Component({
  components: { ChevronDoubleRightIcon },
})
export default class DetalhesProsseguir extends Vue {

  get cotacao() {
    return this.$store.state.cotacao;
  }

  get nextQuery() {
    const query = {} as { ref: string };
    if (this.cotacao.cod_indicador !== 0) {
      query.ref = this.cotacao.cod_indicador as string;
    }
    return query;
  }

  get nextUrl() {
    if (this.cotacao.valor_fipe > 100000) {
      return 'acima100';
    }
    return 'abaixo100';
  }

  public onClick() {
    const query = {} as { ref: string };

    if (this.cotacao.cod_indicador !== 0) {
      query.ref = this.cotacao.cod_indicador as string;
    }

    this.$router.push({
      name: 'resumo',
      params: { arg: this.nextUrl },
      query: this.nextQuery,
    });

    const data = {
      id: this.cotacao.id,
      cobertura: this.cotacao.cobertura,
      valor_cobertura: this.cotacao.valor_cobertura,
      valor_adesao: this.cotacao.valor_adesao,
      valor_mensalidade: this.$store.getters.valor_mensalidade,
    };
    Simulador.update(data).then();

    window.fbq('track', 'Lead');
    window.fbq('track', 'AddToCart', {
      value: this.$store.getters.valor_mensalidade,
      currency: 'BRL',
    });
  }
}
